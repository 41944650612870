import {IoChevronBack, IoChevronForwardOutline} from "react-icons/io5";
import { Settings } from 'react-slick';

function NextArrow(props: any) {
	const { onClick } = props;
	return (
		<div
			className={
				'top-0 w-[44px] h-[44px] shadow bg-white text-blue-5/50 hover:text-blue-2 rounded-full cursor-pointer left-0 absolute center z-10'
			}
			onClick={onClick}
		>
			<IoChevronBack className={'text-2xl duration-200 -ml-1'}/>
		</div>
	);
}

function PrevArrow(props: any) {
	const { onClick } = props;
	return (
		<div
			className={
				'top-0 w-[44px] h-[44px] shadow bg-white text-blue-5/50 hover:text-blue-2 rounded-full cursor-pointer right-0 absolute center z-10'
			}
			onClick={onClick}
		>
			<IoChevronForwardOutline className={'text-2xl duration-200 ml-1'}/>
		</div>
	);
}

export const settings1: Settings = {
	centerMode: true,
	dots: false,
	infinite: true,
	centerPadding: '0',
	speed: 300,
	slidesToShow: 3,
	slidesToScroll: 1,
};

export const settings2 = {
	dots: false,
	infinite: true,
	speed: 300,
	slidesToShow: 1,
	slidesToScroll: 1,
	nextArrow: <PrevArrow />,
	prevArrow: <NextArrow />,
};
