'use client';

import React, { memo } from 'react';
import Slider from 'react-slick';

import { NextImage } from '@/app/_components';

import { dataSimple } from './partner.constant';

const settings = {
	arrows: false,
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 6,
	slidesToScroll: 6,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 2,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
	],
};

const Partner = () => {
	return (
		<div className="container">
			<Slider {...settings} className="px-[2rem]">
				{dataSimple.map((item) => (
					<div key={item.images} className="flex pt-[3rem]">
						<div className="h-[77px] flex flex-1 items-center justify-center">
							<NextImage
								src={item.images}
								placeholder="blur"
								width={item.width}
								height={item.height}
							/>
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
};

export default memo(Partner);
