'use client';

import React from 'react';
import Slider from 'react-slick';

import { LegacyImage } from '@/app/_components';
import { useI18n } from '@/locales/client';

import { TEAM } from './team.constant';

const settings = {
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 4,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 3000,
	cssEase: 'linear',
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 4,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 2,
			},
		},
		{
			breakpoint: 748,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

const ListMember = () => {
	const t: any = useI18n();

	return (
		<Slider {...settings} className="">
			{TEAM.map((item, index) => (
				<div key={index} className="p-[2rem] md:p-3">
					<div className="bg-white flex flex-col justify-center items-center shadow rounded-default mt-[8rem]">
						<div className="relative row justify-center md:w-auto md:h-auto">
							<div className="w-[140px] absolute top-[50%] translate-y-[-50%] h-[140px] ring-4 shadow-md ring-white rounded-full overflow-hidden">
								<LegacyImage
									src={item.pathImage}
									placeholder="blur"
									className={'object-top'}
								/>
							</div>
						</div>
						<div className="p-[30px] mt-[60px]">
							<div className="text-center space-y-1">
								<p className="font-semibold text-blue-2">
									{t(item.name)}
								</p>
								<p className="text-sm text-gray-7 font-medium">
									{t(item.position)}
								</p>
							</div>
							<div className="mt-4 space-x-3">
								<i
									className={
										'drop-shadow c4c-linkdln text-xl text-blue-2 cursor-pointer'
									}
								/>
								<i
									className={
										'drop-shadow c4c-facebook text-xl text-blue-2 cursor-pointer'
									}
								/>
							</div>
						</div>
					</div>
				</div>
			))}
		</Slider>
	);
};

export default ListMember;
