const TEAM = [
	{
		name: 'Tuan Pham',
		position: 'Founder & CEO',
		pathImage: require('./img/anhtuan.jpeg'),
	},
	{
		name: 'Chris (Tuan) Nguyen',
		position: 'Head of Business',
		pathImage: require('./img/huu-tuan.jpg'),
	},
	{
		name: 'Anh Phan',
		position: 'BA Lead',
		pathImage: require('./img/thu-anh.jpeg'),
	},
	{
		name: 'Luan Nguyen',
		position: 'Team Lead',
		pathImage: require('./img/aluan.jpeg'),
	},
	{
		name: 'Long Nguyen',
		position: 'Web Developer',
		pathImage: require('./img/long.jpeg'),
	},
	{
		name: 'Tung Nguyen',
		position: 'Web Developer',
		pathImage: require('./img/team0.jpeg'),
	},
	{
		name: 'Nam Nguyen',
		position: 'Mobile Developer',
		pathImage: require('./img/nam_nguyen.jpeg'),
	},
	{
		name: 'Tuan Le',
		position: 'UI/UX Designer',
		pathImage: require('./img/ken.jpg'),
	},
	{
		name: 'Thinh Nguyen',
		position: 'UI/UX Designer',
		pathImage: require('./img/team0.jpeg'),
	},
	{
		name: 'Khanh Nguyen',
		position: 'QA Lead',
		pathImage: require('./img/khanh.jpeg'),
	},
];

export { TEAM };
