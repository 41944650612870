'use client';
import React, { memo, useCallback, useRef, useState } from 'react';
import Slider from 'react-slick';

import { LegacyImage } from '@/app/_components';
import { useI18n } from '@/locales/client';
import { IReview } from '@/services';

import { settings1, settings2 } from './config';

interface IProps {
	data?: IReview[] | any;
}
const HomeReview = ({ data = [] }: IProps) => {
	const t: any = useI18n();
	const [active, setActive] = useState(0);
	let sliderRef: any = useRef();
	let slider1Ref: any = useRef();

	const onClickSlider2 = (i: number) => {
		sliderRef?.current?.slickGoTo(i);
		slider1Ref?.current?.slickGoTo(i);
		setActive(i);
	};

	const onChangeSlide = (i: number) => {
		setActive(i);
		slider1Ref?.current?.slickGoTo(i);
	};

	const renderSlide1 = useCallback(() => {
		return (
			<Slider
				{...settings1}
				ref={slider1Ref}
				className="flex items-center justify-center lg:w-[40%] md:w-[70%] md:m-auto"
			>
				{data.map((item: any, index: number) => (
					<div key={item?.id.toString()} className="flex flex-1 pb-6">
						<button
							type="button"
							aria-label={'Client'}
							className="p-4 bg-transparent"
							onClick={() => onClickSlider2(index)}
						>
							<div
								className={`flex items-center justify-center relative rounded-full overflow-hidden shadow-card ${
									active === index
										? 'w-[94px] h-[94px]'
										: 'w-[80px] h-[80px] mt-2'
								}`}
							>
								<div
									className={`rounded-full overflow-hidden ${
										active === index
											? 'w-[82px] h-[82px]'
											: 'w-[72px] h-[72px]'
									}`}
								>
									<LegacyImage
										src={
											item?.ownerAvatar?.url ||
											require(`@/app/[locale]/home/Review/images/team1.jpeg`)
										}
										width={82}
										height={82}
										blurDataURL={item?.ownerAvatar?.urlBlur}
									/>
								</div>
							</div>
						</button>
					</div>
				))}
			</Slider>
		);
	}, [active]);

	const renderSlide2 = useCallback(() => {
		return (
			<Slider
				{...settings2}
				className="mt-[1rem]"
				ref={sliderRef}
				afterChange={onChangeSlide}
			>
				{data.map((item: any) => (
					<div
						key={item?.id.toString()}
						className="px-[2rem] md:max-w-[70%]"
					>
						<p className="font-normal text-gray-7">
							{t(item.content)}
						</p>
						<p className="mt-3 font-semibold text-blue-2 ">
							{item.ownerName} - {item.title}
						</p>
					</div>
				))}
			</Slider>
		);
	}, []);

	return (
		<div className="container py-[3rem] md:pt-[5%] text-center">
			<div className="flex flex-col px-[3rem] text-center">
				<p className={`uppercase text-blue-2 font-bold text-2xl`}>
					{t('home.review.title')}
				</p>
				<p
					className={`font-medium text-3xl mt-[1.5rem] leading-tight text-gray-7`}
				>
					{t('home.review.description')}
				</p>
			</div>
			<div className="px-[2rem] md:px-0 mt-[3rem]">
				<div className="bg-gray-1 shadow-card rounded-default md:px-[30px] px-2 py-[40px] ">
					{renderSlide1()}
					{renderSlide2()}
				</div>
			</div>
		</div>
	);
};

export default memo(HomeReview);
