export const dataSimple = [
	{
		images: require('./img/brand1.png'),
		width: 102,
		height: 73,
	},
	{
		images: require('./img/brand2.png'),
		width: 139,
		height: 38,
	},
	{
		images: require('./img/brand3.png'),
		width: 141,
		height: 56,
	},
	{
		images: require('./img/brand4.png'),
		width: 134,
		height: 40,
	},
	{
		images: require('./img/brand5.png'),
		width: 71,
		height: 70,
	},
	{
		images: require('./img/brand6.png'),
		width: 126,
		height: 77,
	},
];
