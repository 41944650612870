import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/form/TextEditor/ReadOnly.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/CText/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/ExamButton/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/Search/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/SmartLink/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/Sort/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/layout/Footer/_component/AuthButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/layout/Footer/Footer.form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/layout/Header/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/layout/Viewer/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/view/Guest/CourseDetail/ListLessons/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/view/Guest/CourseDetail/ProgramDetail/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/Banner/img/bg_banner.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/Banner/img/wave4.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/OutWork/img/client.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/OutWork/img/employee.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/OutWork/img/ourwork.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/OutWork/img/people.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/OutWork/img/profit.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/Partner/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/Review/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/Team/img/bg_team.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/Team/img/wave8.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/Team/ListMember.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/TechUse/img/mobile.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/introduce/desktop.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/introduce/medal1.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/introduce/medal2.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/introduce/medal3.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/introduce/phone.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/introduce/rocket.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/introduce/services1.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/introduce/services2.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/introduce/services3.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/logo/logoc4c.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/solution/iconBox1.jpeg");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/solution/iconBox2.jpeg");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/solution/iconBox3.jpeg")